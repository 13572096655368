import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import '../styles/customer-stories.less'
import SEO from '../components/SEO'
import {
  ConsultationPopButton,
  ConsultationPageForm,
} from '../components/form-biz'
const loadText = require('src/utils').loadText
const data = loadText('customer-stories')
const B1 = () => (
  <div className="customerStory-b1">
    <div className="imgContainer">
      <img
        src="/customer-stories/b1-pic.jpg"
        alt=""
        style={{ position: 'relative', bottom: '65px' }}
      />
    </div>
    <div
      className="description font-20 t-color mt-64"
      style={{ maxWidth: '590px' }}
    >
      {data.b1.description}
    </div>
  </div>
)
const StoryProfile = ({ name, idx, categories, logo, description }) => (
  <div className="customerStory-profile mt-24">
    <div className="name font-28 semi-bold t-color">{name}</div>
    <img
      src={logo}
      alt={name}
      style={{ width: '70px' }}
      onError={(img) => {
        // console.log(img.target)
        ;(img.target as any).src = logo.replace('svg', 'png')
      }}
    />
    {categories.map((c) => (
      <div className="category-tag mt-8">{c}</div>
    ))}
    <div
      className="bg-img mt-24 only-mobile"
      style={{
        backgroundImage: `url("/customer-stories/story-${idx + 1}-bg.jpg")`,
        backgroundPosition: `${idx % 2 === 0 ? 'left' : 'right'} center`,
      }}
    ></div>
    <div className="mt-32 content">{description}</div>
  </div>
)
const StoryCard = ({ results, usedProducts, children, link }: any) => (
  <div
    className="customerStory-card"
    onClick={() => {
      if (link) {
        // window.open(`/customer-story/${link}`)
        window.open(link)
      }
    }}
  >
    <div className="top">
      {children}
      {/* <div className="label">{data.subject.resolution}</div>
      <div>{resolution}</div> */}
      <div className="label mt-32">{data.subject.results}</div>
      {/* <div></div> */}
      {results.map(({ name, type }) => (
        <div className="result-tag">
          {type && (
            <img src={`/customer-stories/arrow-${type}.svg`} alt={type} />
          )}
          {name}
        </div>
      ))}
      <div className="label mt-24">{data.subject.used}</div>
      {usedProducts.map((name) => (
        <div className="product-tag">{name}</div>
      ))}
    </div>
    {link && (
      <div className="customerStory-card-learnmore font-16">
        {data.subject.learnMore}
      </div>
    )}
  </div>
)
const Stories = () => (
  <div className="customerStory-story">
    {data.usecases.map((d, idx) => (
      <div
        className="customerStory-storyContainer clearfix"
        style={{
          backgroundImage:
            d.bg || `url("/customer-stories/story-${idx + 1}-bg.jpg")`,
        }}
      >
        <StoryCard {...d}>
          <StoryProfile
            name={d.name}
            idx={idx}
            categories={d.categories}
            description={d.description}
            logo={
              d.logo ||
              `/customer-stories/story-logo-${idx + 1}.${
                idx < 4 ? 'svg' : 'png'
              }`
            }
          />
        </StoryCard>
      </div>
    ))}
  </div>
)
export const Top = ({ seo, topBanner }) => (
  <div>
    <SEO {...seo} />
    <div className="customerStory-topBannerWrapper">
      <div className="customerStory-topBanner full-bg">
        <Header logo="/logo-blue.svg" />
        <div className="customerStory-topBanner-content">
          <h1 className="title font-56 semi-bold t-color">{topBanner.title}</h1>
          <div className="subtitle mt-24 font-20">{topBanner.subtitle}</div>
          <div className="buttonContainer mt-24">
            <ConsultationPopButton
              buttonClassName="white"
              source="customer-stories"
            >
              {data.topBanner.buttonText}
            </ConsultationPopButton>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="customerStory">
      <Top
        seo={data.seo}
        topBanner={data.topBanner}
        // bgColor="linear-gradient(90deg,rgba(225,245,255,1) 0%,rgba(179,229,255,1) 100%)"
      />
      <B1 />
      <Stories />
      <ConsultationPageForm {...data.form} />
      <Footer />
    </div>
  )
}
