import React from 'react'
import Footer from '../../components/footer'
import '../../styles/customer-story.less'
import '../../styles/customer-story-adtiming.less'
import { Top } from '../../pages/customer-stories'
import { ConsultationPageForm } from '../../components/form-biz'

const Case = ({ name, description, bgPic, logo }) => (
  <div
    className="story-case clearfix"
    style={{
      backgroundImage: `url("${bgPic}")`,
      backgroundPosition: 'center',
      backgroundSize: '1920px auto',
    }}
  >
    <div className="story-case-content">
      <div className="font-28 semi-bold t-color clearfix name">
        {name}
        <img src={logo} alt={name} style={{ width: '70px' }} />
      </div>
      <div
        className="font-20 mt-32"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    {/* </div> */}
  </div>
)
const Stats = ({ title, subtitle, stats }) => (
  <div className="story-stats">
    <div
      className="font-36 semi-bold t-color block-middle  mobile-padding"
      style={{ maxWidth: '1000px' }}
    >
      {title}
    </div>
    <div className="mt-64 font-28 semi-bold t-color">{subtitle}</div>
    <div className="mt-32 block-middle" style={{ maxWidth: '1065px' }}>
      {stats.map(({ name, stat }) => (
        <div className="story-stats-item">
          <div
            className="stats mt-32"
            dangerouslySetInnerHTML={{ __html: stat }}
          ></div>
          <div className="font-20 semi-bold t-color mt-8">{name}</div>
        </div>
      ))}
    </div>
  </div>
)
// const Problem = ({ title, content, bg }) => (
//   <div className="story-problem">
//     <div
//       className="full-bg"
//       style={{ backgroundImage: `url("${bg}")`, backgroundPosition: 'top' }}
//     >
//       <div className="font-28 semi-bold t-color mobile-padding">{title}</div>
//       <div className="mt-8">
//         {content.map(({ title, description }) => (
//           <div className="mt-56">
//             <div
//               className="font-28 semi-bold t-color block-middle  mobile-padding"
//               style={{ maxWidth: '739px' }}
//             >
//               {title}
//             </div>
//             <div
//               dangerouslySetInnerHTML={{ __html: description }}
//               className="story-problem-content block-middle font-20 mt-24"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
// )
// const Result = ({ title, content }) => (
//   <div className="story-result">
//     <div className="story-result-title font-28 semi-bold">{title}</div>
//     <div style={{ background: '#E1F3FE' }}>
//       <div className="story-result-content-container block-middle">
//         {content.map(({ title, description }) => (
//           <div className="story-result-content">
//             <div
//               className="font-28 semi-bold t-color block-middle text-center mt-56"
//               style={{ maxWidth: '643px' }}
//             >
//               {title}
//             </div>
//             <div className="mt-24">
//               {description.map((d) => (
//                 <div
//                   className="content font-20 mt-16"
//                   dangerouslySetInnerHTML={{ __html: d }}
//                 />
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
// )
const B1 = () => {
  return (
    <div className="b1">
      <div className="main-content">
        <div className="left">
          <img src="/adtiming/b1-people.jpg" alt="people" />
        </div>
        <div className="right">
          <p>
            The collaboration with Zenlayer is actually the inevitable result of
            conforming to the development trend of digital technology. AdTiming
            mostly uses Zenlayer Global Accelerator (ZGA) and we have been very
            satisfied with Zenlayer's product quality and services. Especially
            in terms of low latency and high stability of the global network,
            ZGA satisfies our business needs very well.
          </p>
          <p>
            AdTiming's original IT architecture required a lot of manpower in
            basic operation and maintenance, and at the same time failed to
            fully meet the increasing network requirements of our global users.
            As a result, we were urgently looking for technology partners in
            emerging markets around the world. With their swift responses and
            professional and meticulous service, Zenlayer quickly won our trust.
            With the ZGA products, AdTiming quickly achieved global user
            coverage but also realized the 'last mile' local network access in
            emerging markets.
          </p>
          <div className="name">Wentao Zhang</div>
          <div className="job">CTO, AdTiming</div>
        </div>
      </div>
    </div>
  )
}
const B2 = () => {
  return (
    <div className="b2">
      <div className="b2-bg">
        <div className="main-content">
          <div className="content">
            <div className="title">The challenge</div>
            <p>
              With a razor-sharp focus on the bottom line, businesses are quick
              to abandon services they deem unstable, sluggish, or unresponsive.
              In order for AdTiming to provide its premium, data-driven
              advertising content services, user requests had to be aggregated
              from all over the world to the company’s data center in North
              America. This meant that users in geographically distant regions
              with suboptimal networking infrastructure like Southeast Asia,
              South America, and the Middle East frequently experienced high
              latency and instability when accessing AdTiming’s services,
              hurting the company’s brand reputation and customer retention.
            </p>
            <p>
              Furthermore, the constant need to connect to its many global
              clients heavily taxed AdTiming’s internal IT infrastructure,
              putting a strain on the company’s limited manpower and resources
              while skyrocketing costs in operation and maintenance.
            </p>
            <p>
              These compounding factors made it challenging for AdTiming to meet
              rising user demand and expand its global footprint.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
const B3 = () => {
  return (
    <div className="b3">
      <div className="main-content">
        <div className="title">The Solution</div>
        <div className="subtitle">
          Making advertisements more engaging and enjoyable for users
        </div>
        <p>
          A core function of AdTiming’s marketing platform blends engaging video
          advertisements with interactive games to capture user attention and
          generate product awareness. This service requires consistently
          reliable network performance with low latency, as the users of today’s
          fast-paced world are likely to abandon a page or close an app whenever
          an advertisement stalls or fails. Further, lags and stutters are
          exceptionally disruptive to interactive games, making them
          unresponsive and unenjoyable to play.
        </p>
        <p>
          With the help of Zenlayer’s suite of advanced networking services,
          AdTiming was able to significantly reduce its advertising content
          delay and transmission times, lowering the overall latency of its
          content service by up to 45%. To improve the performance and stability
          of the platform’s video advertisements, static requests were routed to
          and fulfilled through Zenlayer’s Content Delivery Network (CDN), which
          intelligently caches content at the edge closest to users for
          lightning-quick distribution. Zenlayer Global Accelerator was used to
          speed up dynamic content delivery to ensure that users had smooth and
          enjoyable gaming experiences within the advertisements.
        </p>
        <img src="/adtiming/b3-pic.jpg" alt="b3-pic" />
      </div>
    </div>
  )
}
const B4 = () => {
  return (
    <div className="b4">
      <div className="main-content">
        <div className="title">
          Elevating user stickiness alongside operational efficiency
        </div>
        <p>
          Using Zenlayer’s high-performance global backbone with 290+ edge nodes
          in both developed and emerging markets, AdTiming improved the speed
          and reliability of its marketing platform and as a result, user
          participation and stickiness - a measure of the frequency of users
          returning to the platform.
        </p>
        <p>
          The private network environment provided by Zenlayer’s cloud
          networking service also shields AdTiming’s internal traffic from the
          noise and congestion of public networks while alleviating the
          company’s greatest resource strains by taking the pressure off its
          origin site. Now that AdTiming runs its operations through a much more
          efficient, private office networking infrastructure, overall employee
          productivity has improved, and remote workers can access their
          corporate mailboxes with greater security.
        </p>
      </div>
    </div>
  )
}
const B5 = () => {
  return (
    <div className="b5">
      <div className="main-content">
        <div className="title">
          Expanding global coverage with 20% savings in operating costs
        </div>
        <p>
          Not only did Zenlayer’s technology help take the stress off AdTiming’s
          origin server and drastically improve the performance of its marketing
          platform, but Zenlayer’s extensive presence throughout the world and
          flexible payment model also made it easy for the company to instantly
          scale its content service to rapidly rising user demand. Without the
          need to scale up equipment or manpower, the company was able to
          successfully expand its global coverage while lowering its overall
          operational costs by 20%.
        </p>
      </div>
    </div>
  )
}
const B6 = () => {
  const content = [
    'Reduced the latency of the advertising content platform by up to 45% with Zenlayer Global Accelerator.',
    'Smoothly expanded global coverage with Zenlayer’s extensive presence around the world, especially in emerging markets.',
    'Improved user satisfaction and stickiness along with internal operational efficiency through Zenlayer’s private cloud networking services.',
    'Leveraged Zenlayer’s global backbone and flexible payment model to alleviate heavy resource strains on IT infrastructure and reduce operating costs by 20%.',
  ]
  return (
    <div className="b6">
      <div className="main-content">
        <div className="title">The results</div>
        <div className="content">
          {content.map((c, i) => (
            <div className="item">
              <img src={`/adtiming/b6-${i}.jpg`} alt={c} />
              <div>{c}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const B7 = () => {
  return (
    <div className="b7">
      <div className="main-content">
        <div className="title">Looking ahead</div>
        <p>
          Zenlayer’s global private network and acceleration platform will
          continue to help AdTiming sustainably develop key areas of its
          business while facilitating the company’s expansion into South
          America, Southeast Asia, Europe, and the United States. Zenlayer
          strives to keep user experience smooth and satisfaction high for
          AdTiming’s marketing platform as the advertising services company
          works to diversify its portfolio with new games, tools, social
          networks, and other applications to enrich its product offerings and
          create more value for customers.
        </p>
      </div>
    </div>
  )
}
const B8 = () => {
  return (
    <div className="b8">
      <div className="b8-container">
        <div className="main-content">
          <div className="title">
            Expand your global footprint with Zenlayer
          </div>
          {/* <img src="/adtiming/b8-pic.jpg" alt="b8-pic" /> */}
          <p>
            A winning combination of Zenlayer Global Accelerator, CDN, and
            networking solutions powered by 290+ edge nodes and delivered with
            our characteristic “Wow” service fast-tracked AdTiming’s global
            expansion while improving user experience and reducing operating
            costs.
          </p>
          <p>
            If your business is looking to expand its global footprint, improve
            experiences for users, or accelerate apps or services, be sure speak
            to a Zenlayer solution expert today!
          </p>
          <p>
            For the fastest service and instant provisioning, check out
            zenConsole — our intuitive self-service platform that lets you
            deploy around the world in minutes.
          </p>
        </div>
      </div>
    </div>
  )
}
export default function Home() {
  const data = {
    seo: {
      title: 'Customer Story - AdTiming | Zenlayer',
      description: '',
      keywords: [],
    },
    topBanner: {
      title: 'Customer Stories',
      subtitle: 'WOW service 24/7',
      buttonText: 'Schedule a free consultation',
    },
    case: {
      name: 'AdTiming',
      description:
        'Founded in 2016, AdTiming is a premium, data-driven ad mediation platform that aims to maximize revenue and improve advertising efficiency for developers, enterprises, and advertisers around the world. The company offers high-quality mobile marketing solutions through cutting-edge technology and in-depth industry R&D to create a closed loop from user acquisition to application monetization, helping clients improve their return on advertising spend (ROI).<br/><br/>To date, AdTiming has partnered with 1500+ enterprises and developers and 30+ Ad Exchange Platforms, catering to a wide range of industries spanning e-commerce, gaming, utility, and social applications.',
      bgPic: '/adtiming/top-bg.jpg',
      logo: '/customer-stories/adtiming.svg',
    },
    byTheNumber: {
      title:
        'AdTiming serves up content with less latency and expands coverage with Zenlayer',
      subtitle: 'By the Numbers',
      stats: [
        {
          name: 'reduced latency',
          stat:
            '<div><span style="font-size: 20px; vertical-align: top;">UP TO</span> 45%</div>',
        },
        { name: 'lower operating costs', stat: '20%' },
      ],
    },
    consultation: {
      title: 'Schedule a free consultation',
      description:
        'Interested in deploying your own manufacturing solution? Our friendly representatives are always available to help.',
    },
  }

  return (
    <div className="story adtiming">
      <Top seo={data.seo} topBanner={data.topBanner} />
      <div
        style={{
          background:
            'linear-gradient(360deg,rgba(255,255,255,1) 0%,rgba(245,249,255,1) 100%)',
        }}
      >
        <Case {...data.case} />
        <Stats {...data.byTheNumber} />
      </div>
      <B1 />
      <B2 />
      <B3 />
      <B4 />
      <B5 />
      <B6 />
      <B7 />
      <B8 />
      <ConsultationPageForm {...data.consultation} source={'AdTiming'} />
      <Footer />
    </div>
  )
}
